import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import { Home } from "./Screen/Home";
import { GeotabProvider } from "./contexts/GeotabContext";
import "./utils/axiosInterceptors";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./utils/theme";
import { Header } from "./components/Header";
import "dayjs/locale/en";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GeotabProvider>
        <Header />
        <ThemeProvider theme={theme}>
          <Home queryClient={queryClient} />
        </ThemeProvider>
      </GeotabProvider>
    </QueryClientProvider>
  );
}

export default App;
