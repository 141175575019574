import React, { FC } from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { ChartData, ChartOptions, ChartType } from "chart.js/auto";
import { Box, Typography } from "@mui/material";

interface BarChartProps {
  type: ChartType;
  data: ChartData<"bar", number[]>;
  options?: ChartOptions<"bar">; // Adding an options prop
  error?: string;
}

export const BarChart: FC<BarChartProps> = ({ type, data, options, error }) => {
  return (
    <Box width={"100%"} height={"100%"} position={"relative"} pr={2}>
      <Chart type={type} data={data} options={options} />
      {error && (
        <Box>
          <Box
            position={"absolute"}
            top={0}
            left={0}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            bgcolor={"rgba(0,0,0,0.5)"}
            color={"white"}
            fontSize={24}
            borderRadius={4}
            p={2}
          >
            <Typography>{error}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
