import { useQuery } from "react-query";
import { useGeotab } from "../contexts/GeotabContext";
import { fetchWithInterceptors } from "../utils/axiosInterceptors";

const coreUrl = process.env.REACT_APP_CORE_URL;

export const useFetchVehicles = () => {
  const { geotabSession, geotabFilters } = useGeotab();

  const {
    data: vehicles,
    error: vehiclesError,
    isLoading: vehiclesLoading,
  } = useQuery(
    ["vehicles", geotabFilters, geotabSession.sessionId],
    async () => {
      const isDefaultFilter =
        JSON.stringify(geotabFilters) ===
        JSON.stringify([{ id: "GroupCompanyId" }]);

      let queryParams = "";
      if (!isDefaultFilter) {
        queryParams = new URLSearchParams({
          groupFilters: JSON.stringify(geotabFilters),
        }).toString();
      }

      const apiRequest = `${coreUrl}/vehicles${queryParams ? "?" + queryParams : ""}`;

      const data = await fetchWithInterceptors(apiRequest);

      return data.vehicles;
    },
    {
      enabled: !!geotabSession.sessionId,
    },
  );

  return {
    vehicles,
    vehiclesError,
    vehiclesLoading,
  };
};
