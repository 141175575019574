export function fetchWithInterceptors(url, options = {}) {
  const requestInterceptor = (url, options) => {
    const token = localStorage.getItem("token");
    options.headers = {
      ...options.headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    return { url, options };
  };

  const responseInterceptor = async (response) => {
    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error:", errorData);
      throw new Error("Error en la solicitud");
    }
    return response;
  };

  const { url: interceptedUrl, options: interceptedOptions } =
    requestInterceptor(url, options);

  return fetch(interceptedUrl, interceptedOptions)
    .then(responseInterceptor)
    .then((response) => response.json());
}
