import { Grid, Typography } from "@mui/material";
import React from "react";
import { useGeotab } from "../contexts/GeotabContext";

export const Header = () => {
  const { geotabSession } = useGeotab();

  const addInUrl = process.env.REACT_APP_ADDIN_URL;

  return (
    <Grid
      container
      gap={2}
      display="flex"
      flex={1}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      p={2}
    >
      <Grid
        item
        xs={12}
        sm={3}
        maxWidth="xs"
        sx={{ textAlign: { xs: "center", sm: "left" } }}
      >
        <a
          href="https://app.moveev.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={`${addInUrl}/moveev.png`} alt="MoveEV" width="250" />
        </a>
      </Grid>
      <Grid item xs={12} sm={3} maxWidth="xs">
        <Typography
          align="right"
          fontWeight={600}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          {geotabSession.firstName && `Welcome, ${geotabSession.firstName}`}
        </Typography>
      </Grid>
    </Grid>
  );
};
