import * as XLSX from "xlsx";

export const exportToExcel = (selectedVehicle, data, filename) => {
  const columnTitles = {
    timestamp: "Event Date",
    KWH: "kWh Charged",
    reimbursement_amount: "Reimbursement",
    cost_per_kwh: "Cost per kWh",
  };

  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");

  // Crear un nuevo libro y una hoja con los datos
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet([
    [
      "Vehicle Name:",
      selectedVehicle?.name || "-",
      "",
      `${month}-${day}-${year}`,
    ],
    ["Serial Number:", selectedVehicle?.serialNumber || "-"],
    ["VIN:", selectedVehicle?.vin || "-"],
    [""], // Dejar una fila vacía entre la información del vehículo y los datos de la tabla
  ]);

  XLSX.utils.sheet_add_json(worksheet, data, {
    header: Object.keys(columnTitles),
    skipHeader: true,
    origin: "A6",
  });

  const headers = Object.values(columnTitles);
  const headerRef = XLSX.utils.encode_cell({ c: 0, r: 4 });
  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: headerRef });

  XLSX.utils.book_append_sheet(workbook, worksheet, "Home Charging Events");

  // Opciones para el archivo
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

  // Función para convertir el contenido binario a octeto
  function s2ab(s) {
    const buf = new ArrayBuffer(s?.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s?.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  // Crear un objeto Blob con los datos y descargar el archivo
  const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.xlsx`;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};
