import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    // Name of the component
    MuiTypography: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins, sans-serif",
          backgroundColor: "white",
          borderRadius: "10px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins, sans-serif",
          backgroundColor: "white",
          borderRadius: "10px",
        },
      },
    },
  },
});
